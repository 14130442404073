<template>
  <div class="h-100 prompt">
    <b-container class="px-4 h-100">
      <b-row class="h-100 align-items-center justify-content-center">
        <b-col cols="auto">
          <div v-for="(text, index) in textRows" :key="index">
            <h4 v-html="textReplacements(text)" />
          </div>
          <button class="mt-5 continue-btn" @click="$emit('continue')">
            {{ $t("button.continue") }}
            <svg
              class="ml-3"
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="16"
              viewBox="0 0 31 16"
            >
              <g fill="none" fill-rule="evenodd" stroke="#131631">
                <path d="M22 0l8 8-8 8M30 8H0"></path>
              </g>
            </svg>
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["surveyStep", "surveyStepDuration"],
  computed: {
    ...mapGetters("stimulus", {
      viewer: "getViewer",
    }),
    textRows() {
      return this.$t("page." + this.viewer + ".prompt." + this.surveyStep);
    },
  },
  methods: {
    textReplacements(text) {
      return text.replace("TIME", this.surveyStepDuration);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.prompt {
  font-family: "Roboto", Arial, sans-serif;
  background-color: rgb(201, 199, 199);
  .continue-btn {
    font-size: 1.2rem;
    font-weight: 200;
    padding: 0.7rem 1.2rem;
    border-radius: 5px;
    // border-radius: 5rem;
    background-color: rgb(241, 241, 241);
    border: 1px solid black;
    &:hover {
      cursor: inherit;
      box-shadow: 0 1px 0 #131631;
      transform: translate(0px, -1px);
      transition: box-shadow 0.2s ease 0s, transform 0.2s ease 0s;
    }
  }
}
</style>
