import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import scrollIntoView from "scroll-into-view";

// TODO: migrate this mixin to a separate file
export const onNavigationBackToSetup = {
  beforeRouteLeave(to, from, next) {
    // Check if navigating back to setup route (workaround because mounted lifecycle hook is not trigger on parent component)
    if (to.name === "setup") {
      this.$store.commit("settings/SET_WEBCAM_SETUP", false);
      this.$store.commit("stimulus/SET_UPLOAD_IMAGES_COMPLETED", false);
      this.$store.commit("stimulus/SET_UPLOAD_ANSWERS_COMPLETED", false);
    }
    next();
  },
};

export const viewComponent = {
  data() {
    return {
      spinnerInterval: null,
      showSpinner: false,
    };
  },
  created() {
    this.RESET_STATE();
  },
  methods: {
    continueSurvey() {
      if (this.surveyStep === "scroll") {
        this.SET_SHOW_PROMPT(false);
        this.showSpinner = true;
        // fetch posts while showing spinner
        this.getPosts();
        this.spinnerInterval = setInterval(() => {
          this.showSpinner = false;
          clearInterval(this.spinnerInterval);
        }, 2000);
      } else if (this.surveyStep === "stimulus") {
        this.SET_SHOW_PROMPT(false);
      } else if (this.surveyStep === "finish") {
        // dont hide prompt and use as background for the loader modal while uploading assets
        this.$emit("finishedRecording");
      }
    },
  },
};

export const postsListComponent = {
  data() {
    return {
      scrollLoggingInterval: null,
      firstSurveyStepTimeout: null,
    };
  },
  created() {
    this.$store.commit("utilities/SET_EMOTIVA_HEADER", false);
  },
  mounted() {
    if (this.surveyStep === "scroll") this.firstSurveyStepEntry();
    if (this.surveyStep === "stimulus") this.secondSurveyStepEntry();
  },
  beforeDestroy() {
    this.resetAll();
  },
  destroyed() {
    this.$store.commit("utilities/SET_EMOTIVA_HEADER", true);
  },
  methods: {
    firstSurveyStepEntry() {
      this.scrollPositionIntervalLogging();
      this.handlePostsLazyLoading();
      window.addEventListener("scroll", this.handlePostsLazyLoading);
      this.resetStimulusImages({
        stimulusToken: this.stimulus[0].stimulusId,
      });
      this.firstSurveyStepTimeout = setTimeout(() => {
        this.SET_SURVEY_STEP("stimulus");
        this.SET_SHOW_PROMPT(true);
      }, this.surveyStepDuration * 1000);
    },
    secondSurveyStepEntry() {
      // save to stimulus store the count of images taken on the first survey step
      this.setSocialsFirstSurveyStepImgCount();
      // disable scrolling ability
      document.body.style.overflow = "hidden";
      // scroll stimulus into view
      scrollIntoView(this.$refs.posts[1].$el, {
        time: 2000,
        cancellable: false,
      });
    },
    scrollPositionIntervalLogging() {
      this.scrollLoggingInterval = setInterval(() => {
        this.SET_SCROLL_POSITION(window.scrollY);
      }, 1000);
    },
    handlePostsLazyLoading() {
      if (
        window.scrollY + window.innerHeight + 300 >=
        document.documentElement.scrollHeight
      ) {
        this.getPosts();
      }
    },
    resetAll() {
      if (this.scrollLoggingInterval) clearInterval(this.scrollLoggingInterval);
      if (this.firstSurveyStepTimeout)
        clearTimeout(this.firstSurveyStepTimeout);
      window.removeEventListener("scroll", this.handlePostsLazyLoading);
      document.body.style.overflow = "inherit";
    },
  },
};

// legacy
export const postsItemComponent = {
  data() {
    return {
      comment: "",
      observer: null,
    };
  },
  mounted() {
    if (this.surveyStep === "scroll") this.createPostViewedObserver();
  },
  beforeDestroy() {
    if (this.observer) this.destroyPostViewedObserver();
  },
  computed: {
    postId() {
      return this.$vnode.key;
    },
    headerImg() {
      return this.post.bodyParsed.header.leftColumn.imageUrl;
    },
    headerInnerImg() {
      return this.post.bodyParsed.header.leftColumn.innerImageUrl;
    },
    postTitle() {
      return this.post.bodyParsed.header.rightColumn.topRow.title || "author";
    },
    verifiedAccount() {
      return this.post.bodyParsed.header.rightColumn.topRow.verifiedAccount;
    },
    posterName() {
      return (
        this.post.bodyParsed.header.rightColumn.bottomRow.posterName || "author"
      );
    },
    bodyText() {
      return this.post.bodyParsed.body.topRow.text;
    },
    bodyContentType() {
      return this.post.bodyParsed.body.bottomRow.contentType;
    },
    bodyImg() {
      return this.post.bodyParsed.body.bottomRow.image.url;
    },
    bodyVideo() {
      return this.post.bodyParsed.body.bottomRow.video.streamModifiedUrl;
    },
    reactionsCounter() {
      return this.post.bodyParsed.footer.reactionsCounter;
    },
    commentsCounter() {
      return this.post.bodyParsed.footer.commentsCounter;
    },
    isFollowed() {
      return this.post.isFollowed;
    },
    isLiked() {
      return this.post.isLiked;
    },
    comments() {
      return this.post.comments;
    },
    isStimulus() {
      if (this.postId === 1) return true;
      else return false;
    },
    postedSince() {
      let date;
      if (this.post.bodyParsed.header.rightColumn.bottomRow.postedSince) {
        date = this.post.bodyParsed.header.rightColumn.bottomRow.postedSince;
      } else {
        const now = new Date();
        const fiveDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000);
        const randomTimestamp =
          Math.floor(Math.random() * (now.getTime() - fiveDaysAgo.getTime())) +
          fiveDaysAgo.getTime();
        date = new Date(randomTimestamp).toISOString();
      }
      dayjs.extend(relativeTime);
      return dayjs(date).fromNow();
    },
  },
  methods: {
    toggleIsLiked() {
      this.SET_POST_IS_LIKED(this.postId);
      if(this.isStimulus)
        this.$store.commit("stimulus/SET_STIMULUS_LIKED");
    },
    postIsViewed() {
      this.SET_POST_IS_VIEWED(this.postId);
    },
    addNewComment() {
      if (this.comment.length > 0) {
        this.SET_POST_COMMENT({ id: this.postId, comment: this.comment });
        this.comment = "";
      }
    },
    createPostViewedObserver() {
      this.observer = new IntersectionObserver(
        (entry) => {
          if (entry[0].isIntersecting) {
            this.postIsViewed();
          }
        },
        {
          threshold: 0.5,
        }
      );
      this.observer.observe(this.$refs.post);
    },
    destroyPostViewedObserver() {
      this.observer.disconnect();
    },
  },
};

export const facebookPost = {
  data(){
    return {
      comment:"",
      observer:null,

    }
  },
  mounted() {
    if (this.surveyStep === "scroll") this.createPostViewedObserver();
  },
  beforeDestroy() {
    if (this.observer) this.destroyPostViewedObserver();
  },
  methods: {
    toggleIsLiked() {
      this.SET_POST_IS_LIKED(this.postId);
      if(this.isStimulus)
        this.$store.commit("stimulus/SET_STIMULUS_LIKED");
    },
    postIsViewed() {
      this.SET_POST_IS_VIEWED(this.postId);
    },
    addNewComment() {
      if (this.comment.length > 0) {
        this.SET_POST_COMMENT({ id: this.postId, comment: this.comment });
        this.comment = "";
      }
    },
    createPostViewedObserver() {
      this.observer = new IntersectionObserver(
        (entry) => {
          if (entry[0].isIntersecting) {
            this.postIsViewed();
          }
        },
        {
          threshold: 0.5,
        }
      );
      this.observer.observe(this.$refs.post);
    },
    destroyPostViewedObserver() {
      this.observer.disconnect();
    },
  },
  computed:{
    postId(){
      return this.$vnode.key;
    },
    postTitle() {
      return this.post.bodyParsed?.header?.rightColumn?.topRow?.title || this.post.bodyParsed.metaData.posterName || "author";
    },
    headerImg() {
      return this.post.bodyParsed?.header?.leftColumn?.imageUrl||this.post.profilePic;
    },
    profilePic(){
      return this.post.bodyParsed?.header?.leftColumn?.imageUrl||this.post.profilePic;
    },
    headerInnerImg(){
      return this.post.bodyParsed?.header?.leftColumn?.innerImageUrl ||this.post.profilePic;
    },
    verifiedAccount(){
      return true
    },
    posterName(){
      return this.post.bodyParsed.header?.rightColumn?.bottomRow?.posterName || this.post.bodyParsed.metaData.posterName || this.post.bodyParsed.username;
    },
    bodyText() {
      return this.post.bodyParsed?.body?.topRow?.text || this.post.bodyParsed.metaData.description;
    },
    bodyContentType() {
      return this.post.bodyParsed?.body?.bottomRow?.contentType ||  this.post.bodyParsed.contentType;
    },
    bodyImg() {
      return this.post.bodyParsed?.body?.bottomRow?.image?.url || this.post.pictures[0];
    },
    bodyVideo() {
      return this.post.bodyParsed?.body?.bottomRow?.video?.streamModifiedUrl || this.post.pictures[0];
    },
    reactionsCounter() {
      return this.post.bodyParsed?.footer?.reactionsCounter || parseInt(this.post.bodyParsed.metaData.reactions).toLocaleString() || 107;
    },
    commentsCounter() {
      return this.post.bodyParsed?.footer?.commentsCounter || parseInt(this.post.bodyParsed.metaData.comments).toLocaleString() || 33;
    },
    isFollowed() {
      return this.post.isFollowed || false;
    },
    isLiked() {
      return this.post.isLiked;
    },
    comments() {
      return this.post.comments;
    },
    isStimulus() {
      if (this.postId === 1) return true;
      else return false;
    },
    dateString(){
      return this.post?.bodyParsed?.header?.rightColumn?.bottomRow?.postedSince || this.post.bodyParsed.metaData.timeAgoPosted
    },
    postedSince() {
      let date;
      if (this.dateString) {
        date = this.dateString;
      } else {
        const now = new Date();
        const fiveDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000);
        const randomTimestamp =
          Math.floor(Math.random() * (now.getTime() - fiveDaysAgo.getTime())) +
          fiveDaysAgo.getTime();
        date = new Date(randomTimestamp).toISOString();
      }
      dayjs.extend(relativeTime);
      return date
      // return dayjs(date).fromNow();
    },
  }
}

export const instagramPost = {
  data() {
    return {
      comment: "",
      observer: null,
    };
  },
  mounted() {
    if (this.surveyStep === "scroll") this.createPostViewedObserver();
  },
  beforeDestroy() {
    if (this.observer) this.destroyPostViewedObserver();
  },
  computed: {
    postId() {
      return this.$vnode.key;
    },
    profilePic() {
      return this.post.bodyParsed?.header?.leftColumn?.imageUrl||this.post.profilePic;
    },
    headerInnerImg() {
      return this.post.bodyParsed?.header?.leftColumn?.innerImageUrl ||this.post.profilePic;
    },
    verifiedAccount() {
      return this.post.bodyParsed.header?.rightColumn?.topRow?.verifiedAccount || this.post.bodyParsed.userDetails.isVerified;
    },
    posterName() {
      return (
        this.post.bodyParsed.header?.rightColumn?.bottomRow?.posterName || this.post.bodyParsed.userDetails?.username || "author"
      );
    },
    description() {
      return this.post.bodyParsed?.body?.topRow?.text || this.post.bodyParsed.content.description;
    },
    contentType() {
      return this.post.bodyParsed?.body?.bottomRow?.contentType|| this.post.bodyParsed.content.contentType;
    },
    bodyImg() {
      return this.post.bodyParsed?.body?.bottomRow?.image.url || this.post.pictures[0] || "";
    },
    bodyVideo() {
      return this.post.bodyParsed?.body?.bottomRow?.video?.streamModifiedUrl || this.post.pictures[0];
    },
    reactionsCounter() {
      return this.post.bodyParsed?.footer?.reactionsCounter || this.post.bodyParsed.postDetails.likeCount.toLocaleString() || 100;
    },
    commentsCounter() {
      return this.post.bodyParsed?.footer?.commentsCounter || this.post.bodyParsed.postDetails.commentCount.toLocaleString();
    },
    isFollowed() {
      return this.post.isFollowed;
    },
    isLiked() {
      return this.post.isLiked;
    },
    comments() {
      return ""
    },
    isStimulus() {
      if (this.postId === 1) return true;
      else return false;
    },
    postedSince() {
      return `${Math.floor(Math.random() * 23) + 1}h`
      // const currDate = Date.now();
      // const publisheDate = this.post.bodyParsed.postDetails.date;
      // const timeDifference = currDate - publisheDate;
      // var seconds = Math.floor(timeDifference / 1000) % 60;
      // var minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
      // var hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
      // var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
      // if(days > 0)return `${days %10}d`
      // if(hours > 0)return `${hours %24}h`
      // if(minutes>0)return `${minutes%60}m`
      // return `${seconds%60}m`

    },
  },
  methods: {
    toggleIsLiked() {
      this.SET_POST_IS_LIKED(this.postId);
      if(this.isStimulus)
        this.$store.commit("stimulus/SET_STIMULUS_LIKED");
    },
    postIsViewed() {
      this.SET_POST_IS_VIEWED(this.postId);
    },
    addNewComment() {
      if (this.comment.length > 0) {
        this.SET_POST_COMMENT({ id: this.postId, comment: this.comment });
        this.comment = "";
      }
    },
    createPostViewedObserver() {
      this.observer = new IntersectionObserver(
        (entry) => {
          if (entry[0].isIntersecting) {
            this.postIsViewed();
          }
        },
        {
          threshold: 0.5,
        }
      );
      this.observer.observe(this.$refs.post);
    },
    destroyPostViewedObserver() {
      this.observer.disconnect();
    },
  },
};
